import { About } from "../About/About";
import { ScreenGrid } from "../ScreenGrid/ScreenGrid";
import { FullscreenButton } from "../FullscreenButton/FullscreenButton";
import { AlignCamera } from "../AlignCamera/AlignCamera";
import { Ruler } from "../Ruler/Ruler";
import { SecondWindow } from "../SecondWindow/SecondWindow";
import { Support } from "../Support/Support";
import { Ad } from "../Ad/Ad";
import { TestMic } from "../TestMic/TestMic";

export const App = () => (
  <>
    <ScreenGrid
      quadrantLeftTop={
        <>
          <FullscreenButton />
          <Ruler />
          <SecondWindow />
        </>
      }
      quadrantLeftBottom={
        <>
          <AlignCamera />
          <TestMic />
        </>
      }
      quadrantRightTop={
        <>
          <Support />
          <Ad />
        </>
      }
    />
    <About />
  </>
);
