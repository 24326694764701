/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import ReactDOM from "react-dom";

export const alignCameraButtonStyles = css({
  appearance: `none`,
  gridColumn: `2 / 3`,
  gridRow: `2 / 3`,
  border: `0`,
  fontSize: `0.8125rem`,
  color: `black`,
  background: `rgba(239, 239, 239, 0.5)`,
  cursor: `pointer`,
  overflow: `hidden`,
  wordBreak: `break-all`,
  hyphens: `auto`,

  "@media (max-width: 600px)": {
    gridColumn: `1 / 3`,
  },
});

export const cameraStyles = css({
  position: `fixed`,
  top: `0`,
  left: `0`,
  width: `100vw`,
  height: `100vh`,
  transform: `rotateY(180deg)`,
  zIndex: `0`,
});

export const Camera = () => {
  const videoEl = React.useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    const camera = videoEl.current;

    if (camera) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: "user",
            width: {
              ideal: 1920,
            },
            height: {
              ideal: 1080,
            },
          },
        })
        .then(function (stream) {
          camera.srcObject = stream;
        });
    }

    return () => {
      if (!camera) {
        return;
      }

      const stream = camera.srcObject as MediaStream;

      if (stream) {
        const tracks = stream.getTracks();

        for (const track of tracks) {
          track.stop();
        }

        camera.srcObject = null;
      }
    };
  });

  return <video ref={videoEl} playsInline autoPlay css={cameraStyles}></video>;
};

export const AlignCamera = () => {
  const [isCameraOn, setIsCameraOn] = React.useState(false);

  const toggleCamera = () => {
    setIsCameraOn((isCameraOn) => !isCameraOn);
  };

  return (
    <>
      <button onClick={toggleCamera} css={alignCameraButtonStyles}>
        {isCameraOn ? `Disable` : `Align`}
        <br />
        {`Camera`}
      </button>
      {isCameraOn &&
        ReactDOM.createPortal(
          <Camera />,
          document.getElementById("root") as HTMLElement,
        )}
    </>
  );
};
