/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import paypal from "./paypal.png";
import kofi from "./kofi.png";
import bmc from "./bmc.png";

export const supportStyles = css({
  gridColumn: `2 / 3`,
  gridRow: `2 / 3`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `center`,
  border: `0`,
  textAlign: `center`,
  fontSize: `0.8125rem`,
  color: `black`,
  textDecoration: `none`,
  background: `rgba(239, 239, 239, 0.5)`,
  overflow: `hidden`,
  wordBreak: `break-all`,
  hyphens: `auto`,

  "@media (max-width: 600px)": {
    gridColumn: `1 / 3`,
  },
});

export const linkStyles = css({
  display: `flex`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  gap: `0.5em`,
  marginBlockStart: `0.5em`,

  img: {
    width: `2em`,
    height: `2em`,
  },
});

export const Support = () => {
  return (
    <div css={supportStyles}>
      <strong>{`Support`}</strong>
      <div css={linkStyles}>
        <a
          href="https://www.paypal.com/donate/?hosted_button_id=YAGF2B4YTHG5L"
          target="_blank"
          rel="noreferrer"
        >
          <img src={paypal} alt="with PayPal" />
        </a>
        <a
          href="https://ko-fi.com/centerofmyscreen"
          target="_blank"
          rel="noreferrer"
        >
          <img src={kofi} alt="with Ko-fi" />
        </a>
        <a
          href="https://buymeacoffee.com/centerofmyscreen"
          target="_blank"
          rel="noreferrer"
        >
          <img src={bmc} alt="with Buy Me a Coffee" />
        </a>
      </div>
    </div>
  );
};
