import { useState } from "react";

export const About = () => {
  const [isOpen, setIsOpen] = useState(
    localStorage.isAboutViewed ? false : true,
  );

  return !isOpen ? null : (
    <div
      style={{
        position: `fixed`,
        top: `0`,
        left: `0`,
        right: `0`,
        bottom: `0`,
        display: `grid`,
        justifyItems: `center`,
        overflow: `auto`,
        zIndex: `2`,
      }}
    >
      <dialog
        open
        style={{
          position: `relative`,
          marginBlock: `1rem`,
          maxWidth: `55ch`,
          boxShadow: `0 0 1rem #000`,
        }}
      >
        <form method="dialog">
          <h1
            style={{
              marginTop: `0`,
            }}
          >
            About <strong>Center of My Screen</strong>
          </h1>
          <p>
            Find the physical center of your screen on any device with this
            minimalist, installable web app.
          </p>
          <h2>Multiple Screens</h2>
          <p>
            The "Second Window" button can help you do this for multiple screens
            or sections of your screen at the same time.
          </p>
          <h2>Ruler App</h2>
          <p>
            Provide your diagonal screen size to display a true-scale ruler
            onscreen. Enter the size in inches, cm, parsecs &mdash; it's up to
            you!
          </p>
          <menu
            style={{
              padding: `0`,
            }}
          >
            <button
              value="default"
              onClick={() => {
                localStorage.isAboutViewed = true;
                setIsOpen(false);
              }}
            >
              Okay, don't show this again.
            </button>
          </menu>
        </form>
      </dialog>
    </div>
  );
};
