/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { type ChangeEventHandler, useRef } from "react";

import { SoundBar } from "./SoundBar";

export const testMicButtonStyles = css({
  appearance: `none`,
  gridColumn: `3 / 4`,
  gridRow: `2 / 3`,
  border: `0`,
  fontSize: `0.8125rem`,
  color: `black`,
  background: `rgba(239, 239, 239, 0.5)`,
  cursor: `pointer`,
  overflow: `hidden`,
  wordBreak: `break-all`,
  hyphens: `auto`,

  "@media (max-width: 600px)": {
    gridColumn: `3 / 5`,
  },
});

export const testMicOutputStyles = css({
  appearance: `none`,
  gridColumn: `3 / 4`,
  gridRow: `2 / 3`,
  border: `0`,
  fontSize: `0.8125rem`,
  color: `black`,
  background: `rgba(239, 239, 239, 0.5)`,
  overflow: `hidden`,
  wordBreak: `break-all`,
  hyphens: `auto`,

  "@media (max-width: 600px)": {
    gridColumn: `3 / 5`,
  },

  select: {
    width: `100%`,
  },

  progress: {
    width: `100%`,
    height: `1.5em`,
  },

  button: {
    paddingInline: `0`,
    width: `50%`,
    lineHeight: `1em`,
  },
});

type Microphone = {
  label: string;
  deviceId: string;
};

export const TestMic = () => {
  const [isMicOn, setIsMicOn] = React.useState(false);
  const [microphones, setMicrophones] = React.useState<Microphone[]>([]);
  const [deviceId, setDeviceId] = React.useState("");
  const [isRecording, setIsRecording] = React.useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const streamRef = useRef<MediaStream | null>(null);
  // Reference to the audio player.
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);

  const toggleMic = () => {
    if (isMicOn) {
      // If mic is being turned off, stop any ongoing recording.
      if (isRecording && mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current = null;
        setIsRecording(false);
      }
      // Stop the media stream immediately.
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null;
      }
      // Stop any audio playback.
      if (audioPlayerRef.current) {
        audioPlayerRef.current.pause();
        audioPlayerRef.current.currentTime = 0;
        audioPlayerRef.current = null;
      }
      // Clear audio chunks.
      audioChunksRef.current = [];
    }
    setIsMicOn((prevIsMicOn) => !prevIsMicOn);
  };

  const handleDeviceChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    setDeviceId(event.target.value);
  };

  const handleRecordPlaybackClick = () => {
    if (!isRecording) {
      // Stop any existing playback.
      if (audioPlayerRef.current) {
        audioPlayerRef.current.pause();
        audioPlayerRef.current.currentTime = 0;
        audioPlayerRef.current = null;
      }

      // Start recording.
      navigator.mediaDevices
        .getUserMedia({ audio: { deviceId: deviceId || undefined } })
        .then((stream) => {
          streamRef.current = stream;
          const mediaRecorder = new MediaRecorder(stream);
          mediaRecorderRef.current = mediaRecorder;

          mediaRecorder.ondataavailable = (event) => {
            audioChunksRef.current.push(event.data);
          };

          mediaRecorder.onstop = () => {
            // Create a blob from the recorded audio chunks.
            const audioBlob = new Blob(audioChunksRef.current, {
              type: "audio/wav",
            });

            const url = URL.createObjectURL(audioBlob);
            audioChunksRef.current = [];

            // Play back the audio immediately.
            const audio = new Audio(url);
            audioPlayerRef.current = audio;
            audio
              .play()
              .catch((err) => console.error("Error playing audio", err));
          };

          mediaRecorder.start();
          setIsRecording(true);
        })
        .catch((err) => console.error("Error accessing microphone", err));
    } else {
      // Stop recording.
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current = null;
      }

      // Stop the media stream immediately.
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null;
      }

      setIsRecording(false);
    }
  };

  // Get mic permission and enumerate devices when the mic is turned on.
  React.useEffect(() => {
    if (!isMicOn) {
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            const mics = devices
              .filter((device) => device.kind === "audioinput")
              .map((device) => ({
                label: device.label || "Unknown Microphone",
                deviceId: device.deviceId,
              }));

            setMicrophones(mics);
            setDeviceId(mics[0]?.deviceId || "");
          })
          .catch((err) => console.error("Error enumerating devices", err));

        // We can stop this initial stream as we only needed it to get
        // permissions.
        stream.getTracks().forEach((track) => track.stop());
      })
      .catch((err) => console.error("Error accessing media devices", err));
  }, [isMicOn]);

  return !isMicOn ? (
    <button onClick={toggleMic} css={testMicButtonStyles}>
      {`Test`}
      <br />
      {`Microphones`}
    </button>
  ) : (
    <div css={testMicOutputStyles}>
      <strong>{`Test Mics`}</strong>
      <select onChange={handleDeviceChange} value={deviceId}>
        {microphones.map((mic) => (
          <option key={mic.deviceId} value={mic.deviceId}>
            {mic.label}
          </option>
        ))}
      </select>
      <SoundBar deviceId={deviceId} />
      <button
        onClick={handleRecordPlaybackClick}
        title={isRecording ? "Playback" : "Record"}
      >
        {isRecording ? `►` : `⏺`}
      </button>
      <button onClick={toggleMic} title="Stop">{`⏹`}</button>
    </div>
  );
};
