/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";

export const soundBarStyles = css({
  width: `50%`,
});

type SoundBarProps = {
  deviceId: string;
};

export const SoundBar = ({ deviceId }: SoundBarProps) => {
  const [volume, setVolume] = React.useState(0);
  const audioContextRef = React.useRef<AudioContext | null>(null);
  const analyserRef = React.useRef<AnalyserNode | null>(null);
  const micStreamRef = React.useRef<MediaStream | null>(null);

  React.useEffect(() => {
    audioContextRef.current = new AudioContext();
    analyserRef.current = audioContextRef.current.createAnalyser();
    analyserRef.current.fftSize = 2048;

    const getMicrophoneInput = async () => {
      try {
        micStreamRef.current = await navigator.mediaDevices.getUserMedia({
          audio: !deviceId || {
            deviceId: {
              exact: deviceId,
            },
          },
        });

        if (audioContextRef.current && micStreamRef.current) {
          const microphone = audioContextRef.current.createMediaStreamSource(
            micStreamRef.current
          );
          if (analyserRef.current) {
            microphone.connect(analyserRef.current);
            loop();
          }
        }
      } catch (err) {
        console.error("Error accessing the microphone", err);
      }
    };

    const loop = () => {
      if (!analyserRef.current) return;

      const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
      analyserRef.current.getByteFrequencyData(dataArray);

      let sum = 0;
      for (let i = 0; i < dataArray.length; i++) {
        sum += dataArray[i] * dataArray[i];
      }
      let rms = Math.sqrt(sum / dataArray.length);
      let normalizedVolume = (rms / 72) * 100;

      setVolume(normalizedVolume);
      requestAnimationFrame(loop);
    };

    getMicrophoneInput();

    return () => {
      micStreamRef.current?.getTracks().forEach((track) => track.stop());
      audioContextRef.current?.close();
    };
  }, [deviceId]);

  return (
    <progress max="100" value={Math.min(volume, 100).toFixed(2)}></progress>
  );
};
