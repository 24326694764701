import React from "react";

export const Ad = () => {
  React.useEffect(() => {
    try {
      (window as any)._mNHandle.queue.push(function () {
        (window as any)._mNDetails.loadTag("922834504", "300x250", "922834504");
      });
    } catch (error) {}
  }, []);

  return <div id="922834504"></div>;
};
