/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";

export const fullscreenButtonStyles = css({
  appearance: `none`,
  gridColumn: `2 / 3`,
  gridRow: `2 / 3`,
  border: `0`,
  fontSize: `0.8125rem`,
  color: `black`,
  background: `rgba(239, 239, 239, 0.5)`,
  cursor: `pointer`,
  overflow: `hidden`,
  wordBreak: `break-all`,
  hyphens: `auto`,

  "@media (max-width: 600px)": {
    gridColumn: `1 / 3`,
  },
});

const { documentElement } = document;

const toggleFullScreen = () => {
  if (!document.fullscreenElement) {
    documentElement.requestFullscreen();
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
};

export const FullscreenButton = () => {
  const [isFullscreen, setIsFullscreen] = React.useState(false);

  React.useEffect(() => {
    const onFullscreenChange = (event: any) => {
      const el = event.target;
      setIsFullscreen(document.fullscreenElement === el);
    };

    documentElement.addEventListener(`fullscreenchange`, onFullscreenChange);
    documentElement.addEventListener(
      `webkitfullscreenchange`,
      onFullscreenChange
    );

    return () => {
      documentElement.removeEventListener(
        `fullscreenchange`,
        onFullscreenChange
      );
      documentElement.removeEventListener(
        `webkitfullscreenchange`,
        onFullscreenChange
      );
    };
  }, []);

  return !document.fullscreenEnabled &&
    !(document as any).webkitFullscreenEnabled ? null : (
    <button onClick={toggleFullScreen} css={fullscreenButtonStyles}>
      {isFullscreen ? `Exit` : `Go`}
      <br />
      {`Fullscreen`}
    </button>
  );
};
