/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const secondWindowStyles = css({
  gridColumn: `2 / 3`,
  gridRow: `3 / 4`,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  border: `0`,
  textAlign: `center`,
  fontSize: `0.8125rem`,
  color: `black`,
  textDecoration: `none`,
  background: `rgba(239, 239, 239, 0.5)`,
  cursor: `pointer`,
  overflow: `hidden`,
  wordBreak: `break-all`,
  hyphens: `auto`,

  "@media (max-width: 600px)": {
    gridColumn: `1 / 3`,
  },
});

export const SecondWindow = () => (
  <a href="." target="_blank" css={secondWindowStyles}>
    <span>
      {`Second`}
      <br />
      {`Window`}
    </span>
  </a>
);
