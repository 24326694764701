import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { App } from "./components/App/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorkerRegistration.register();

function sendToGoogleAnalytics({
  name,
  delta,
  id,
}: {
  name: string;
  delta: number;
  id: string;
}) {
  (window as any).gtag("event", name, {
    event_category: "Web Vitals",
    event_label: id,
    value: Math.round(name === "CLS" ? delta * 1000 : delta),
    non_interaction: true,
  });
}

reportWebVitals(sendToGoogleAnalytics);
