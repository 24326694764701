/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";

export const rulerFormStyles = css({
  gridColumn: `3 / 4`,
  gridRow: `2 / 3`,
  fontSize: `0.8125rem`,
  border: `0`,
  background: `rgba(239, 239, 239, 0.5)`,

  "@media (max-width: 650px)": {
    gridColumn: `3 / 5`,
  },
  "@media (max-height: 600px)": {
    gridRow: `2 / 4`,
  },

  "strong, label": {
    display: `block`,
    cursor: `default`,
  },
  "label[for]": {
    cursor: `pointer`,
  },
  input: {
    boxSizing: `border-box`,
    width: `100%`,
    fontSize: `1rem`,
  },

  button: {
    paddingInline: `0`,
    width: `33.333%`,
    color: `black`,
  },
});

export const rulerStyles = (
  pixelsPerInch: number,
  inchesWide: number,
  inchesTall: number,
) =>
  css({
    position: `fixed`,
    width: `100vw`,
    height: `100vh`,
    pointerEvents: `none`,

    "#horizontal, #vertical": {
      position: `absolute`,
      top: `calc(50% - ${pixelsPerInch}px)`,
      left: `0`,
      display: `grid`,
      gridTemplateColumns: `repeat(${
        (inchesWide + 1) | 0
      }, ${pixelsPerInch}px)`,
      gridTemplateRows: `${pixelsPerInch}px`,
    },

    "#vertical": {
      top: `-${pixelsPerInch}px`,
      left: `50%`,
      gridTemplateColumns: `repeat(${
        (inchesTall + 1) | 0
      }, ${pixelsPerInch}px)`,
      transformOrigin: `bottom left`,
      transform: `rotate(90deg)`,
    },
  });

export const inchStyles = (pixelsPerInch: number) =>
  css({
    counterIncrement: `whole-inch`,
    position: `relative`,

    ".tick": {
      position: `absolute`,
      bottom: `0`,
      width: `1px`,
      background: `black`,
    },
    ".whole-inch": {
      right: `0`,
      height: `100%`,

      "&::before": {
        content: `counter(whole-inch)`,
        position: `absolute`,
        top: `0`,
        right: `calc(100% + ${pixelsPerInch * 0.4 * 0.2}px)`,
        lineHeight: `${pixelsPerInch * 0.4}px`,
        fontSize: `${pixelsPerInch * 0.4}px`,
      },
    },
    ".half-inch": {
      right: `50%`,
      height: `50%`,
    },
    ".quarter-inch": {
      height: `43.75%`,
    },
    ".eighth-inch": {
      height: `37.5%`,
    },
    ".sixteenth-inch": {
      height: `25%`,
    },
  });

export const Inch = ({ pixelsPerInch }: { pixelsPerInch: number }) => (
  <div css={inchStyles(pixelsPerInch)}>
    {pixelsPerInch >= 1.5625 && <div className="whole-inch tick"></div>}
    {pixelsPerInch >= 3.125 && <div className="half-inch tick"></div>}
    {pixelsPerInch >= 6.25 &&
      Array(4)
        .fill(0)
        .map((zero, index) => (
          <div
            key={index}
            className="quarter-inch tick"
            style={{
              right: `${25 * index}%`,
            }}
          ></div>
        ))}
    {pixelsPerInch >= 12.5 &&
      Array(8)
        .fill(0)
        .map((zero, index) => (
          <div
            key={index}
            className="eighth-inch tick"
            style={{
              right: `${12.5 * index}%`,
            }}
          ></div>
        ))}
    {pixelsPerInch >= 25 &&
      Array(16)
        .fill(0)
        .map((zero, index) => (
          <div
            key={index}
            className="sixteenth-inch tick"
            style={{
              right: `${6.25 * index}%`,
            }}
          ></div>
        ))}
  </div>
);

export const Ruler = () => {
  const { screen, outerWidth, innerWidth } = window;

  const [viewportWidth, setViewportWidth] = React.useState(innerWidth);
  const [screenSize, setScreenSize] = React.useState(Number(``));
  const [displayHorizontal, setDisplayHorizontal] = React.useState(
    screen.width > screen.height,
  );
  const [displayVertical, setDisplayVertical] = React.useState(
    screen.height > screen.width,
  );

  React.useEffect(() => {
    const onResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener(`resize`, onResize);

    return () => {
      window.removeEventListener(`resize`, onResize);
    };
  }, []);

  const onHoriz = React.useCallback(() => {
    setDisplayHorizontal(true);
    setDisplayVertical(false);
  }, []);
  const onVert = React.useCallback(() => {
    setDisplayHorizontal(false);
    setDisplayVertical(true);
  }, []);
  const onBoth = React.useCallback(() => {
    setDisplayHorizontal(true);
    setDisplayVertical(true);
  }, []);

  const [pixelsPerInch, inchesWide, inchesTall] = React.useMemo(() => {
    const pixelWidth = screen.width;
    const pixelHeight = screen.height;
    const pixelDiagonal = Math.sqrt(pixelWidth ** 2 + pixelHeight ** 2);

    const browserZoom = outerWidth / viewportWidth;

    const pixelsPerInch = pixelDiagonal / screenSize / browserZoom;

    return [
      pixelsPerInch,
      pixelWidth / pixelsPerInch,
      pixelHeight / pixelsPerInch,
    ];
  }, [viewportWidth, outerWidth, screen.height, screen.width, screenSize]);

  return (
    <>
      <div css={rulerFormStyles}>
        <strong>{`Display Ruler`}</strong>
        <label htmlFor="screen-size">{`Screen Size:`}</label>
        <input
          id="screen-size"
          type="number"
          step="0.1"
          inputMode="decimal"
          placeholder="13.3"
          onChange={({ target: { value: size } }) => {
            setScreenSize(Number(size.trim()));
          }}
        />
        <button onClick={onHoriz}>{`⬌`}</button>
        <button onClick={onVert}>{`⬍`}</button>
        <button onClick={onBoth}>{`✥`}</button>
      </div>

      {screenSize > 0 && pixelsPerInch >= 1.5625 && (
        <div css={rulerStyles(pixelsPerInch, inchesWide, inchesTall)}>
          {displayHorizontal && (
            <div id="horizontal">
              {Array((inchesWide + 1) | 0)
                .fill(0)
                .map((zero, index) => (
                  <Inch key={index} pixelsPerInch={pixelsPerInch} />
                ))}
            </div>
          )}
          {displayVertical && (
            <div id="vertical">
              {Array((inchesTall + 1) | 0)
                .fill(0)
                .map((zero, index) => (
                  <Inch key={index} pixelsPerInch={pixelsPerInch} />
                ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};
